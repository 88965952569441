import { inject, Injectable, signal } from '@angular/core';
import { globalCatchError } from '@pixels/client/app-injector/global-catch-error';
import { OpenChatV2ApiService } from '@pixels/client/open-chat-v2/open-chat-v2-api.service';
import { debounceTime, forkJoin, Observable, Subject, switchMap, tap } from 'rxjs';
import { ApplyChatApiService } from './apply-chat-api.service';

@Injectable({ providedIn: 'root' })
export class InstaChatCountState {
  public readonly appliedCount = signal(0);
  public readonly receivedCount = signal(0);
  public readonly unreadChatMessageTotalCount = signal(0);
  private readonly fetchApplyCountsSource = new Subject<void>();
  private readonly fetchTotalUnreadCountSource = new Subject<void>();
  private readonly applyChatApiService = inject(ApplyChatApiService);
  private readonly openChatV2ApiService = inject(OpenChatV2ApiService);

  public get init$(): Observable<any> {
    return forkJoin([
      this.fetchApplyCounts$,
      this.fetchTotalUnreadCounts$,
    ]);
  }

  private get fetchApplyCounts$(): Observable<any> {
    return this.fetchApplyCountsSource.pipe(
      debounceTime(100),
      switchMap(() => this.applyChatApiService.fetchCounts()),
      tap(applyChatCounts => {
        this.setAppliedCount(applyChatCounts.applied);
        this.setReceivedCount(applyChatCounts.received);
      }),
      globalCatchError()
    );
  }

  private get fetchTotalUnreadCounts$(): Observable<any> {
    return this.fetchTotalUnreadCountSource.pipe(
      debounceTime(100),
      switchMap(() => this.openChatV2ApiService.getTotalUnreadCountByMe()),
      tap(({ totalUnreadCount }) => this.setUnreadChatMessageTotalCount(totalUnreadCount)),
      globalCatchError()
    );
  }

  public triggerFetchApplyCounts(): void {
    this.fetchApplyCountsSource.next();
  }

  public triggerFetchTotalUnreadCount(): void {
    this.fetchTotalUnreadCountSource.next();
  }

  public setAppliedCount(count: number): void {
    this.appliedCount.set(Math.max(count, 0));
  }

  public setReceivedCount(count: number): void {
    this.receivedCount.set(Math.max(count, 0));
  }

  public setUnreadChatMessageTotalCount(count: number): void {
    this.unreadChatMessageTotalCount.set(Math.max(count, 0));
  }

  public decreaseUnreadChatMessageTotalCount(decreaseCount: number): void {
    const originCount = this.unreadChatMessageTotalCount();
    this.setUnreadChatMessageTotalCount(originCount - decreaseCount);
  }
}
