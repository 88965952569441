import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApplyChatCounts } from '@pixels/universal/model/insta-chat/apply-chat/apply-chat.model';
import { ApplyChat, ApplyChatForChatRoom } from '@pixels/universal/model/insta-chat/apply-chat/apply-chat.schema';
import { ApplyByChatRoomDto } from '@pixels/universal/model/insta-chat/apply-chat/dto/apply-by-chat-room-dto';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplyChatApiService {
  private readonly httpClient = inject(HttpClient);

  public fetchCounts(): Observable<ApplyChatCounts> {
    return this.httpClient.get<ApplyChatCounts>('/apply-chats/counts');
  }

  public applyChat(chatRoomId: string, dto: ApplyByChatRoomDto): Observable<ApplyChatForChatRoom> {
    return this.httpClient.put<ApplyChatForChatRoom>(`/apply-chats/apply-by-chat-room/${chatRoomId}`, dto);
  }

  public updateApplyChatUpdatedAtByChatRoomId(chatRoomId: string): Observable<void> {
    return this.httpClient.put<void>(`/apply-chats/updated-by-chat-room/${chatRoomId}`, {});
  }

  public fetchOneByUserIdAndWithMe(targetUserId: string): Observable<ApplyChat> {
    return this.httpClient.get<ApplyChat>(`/apply-chats/by-user-id/${targetUserId}`);
  }

  public fetchApplyByChatRoom(chatRoomId: string): Observable<ApplyChatForChatRoom> {
    return this.httpClient.get<ApplyChatForChatRoom>(`/apply-chats/by-chat-room/${chatRoomId}`);
  }
}
